import React, { FunctionComponent } from "react"
import classNames from "classnames"
import Image from "gatsby-image"
import { Link } from "../link/link"
import { useImageLayout } from "./product-image-hooks"
import styles from "./product-image.module.scss"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"

export interface ProductImageProps {
  className?: string
  alt: string
  src: string
  url?: string
  size?: number
}

export const ProductImage: FunctionComponent<ProductImageProps> = ({
  className,
  alt,
  src,
  url,
  size
}) => {
  const offset = useImageLayout(src, size)

  const fluid = getFluidGatsbyImage(src, { maxWidth: 800 })

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div
        className={styles.offset}
        style={{
          left: offset.x,
          top: offset.y,
          width: offset.scale,
          height: offset.scale,
          justifyContent: offset.center ? "center" : "flex-end",
          zIndex: 1
        }}
      >
        {url ? (
          <Link className={styles.link} to={url}>
            {fluid && (
              <Image alt={alt} fluid={fluid} className={styles.image} />
            )}
          </Link>
        ) : (
          fluid && <Image alt={alt} fluid={fluid} className={styles.image} />
        )}
      </div>
    </div>
  )
}
